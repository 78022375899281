import axios from "axios"
import Cookies from "js-cookie"
export default {
  data() {
    return {
      main_page_switch: false,
    }
  },
  methods: {
    searchJson: function (data, searchCriteria) {
      return data.filter((item) => {
        return Object.keys(searchCriteria).every((key) => {
          // 如果 key 是 content 裡的屬性
          if (key.startsWith("content.")) {
            const contentKey = key.split(".")[1]
            if (Array.isArray(item.content[contentKey])) {
              return searchCriteria[key].every((val) => item.content[contentKey].includes(val))
            }
            return item.content[contentKey] === searchCriteria[key]
          }

          // 處理日期範圍搜尋
          if (key === "open_d" || key === "close_d") {
            const searchDate = new Date(searchCriteria[key])
            const itemDate = new Date(item[key])
            return searchDate.getTime() <= itemDate.getTime()
          }

          // 一般字串搜尋（包含部分匹配）
          if (typeof item[key] === "string") {
            return item[key].toLowerCase().includes(searchCriteria[key].toLowerCase())
          }

          // 精確匹配
          return item[key] === searchCriteria[key]
        })
      })
    },
    checkLoginTokeVaild(login_token) {
      const me = this
      const json_d = { token: login_token }

      axios({
        method: "POST",
        url: `${me.$apiUrl2}checkLoginTokeVaild`,
        data: json_d,
        headers: {
          Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
          "Content-Type": "application/json",
        },
      }).then(function (response) {
        const res = response.data

        if (res.status === 201) {
          if (res.statusText === "Select") {
            me.user_uuid = res.data.id
            sessionStorage.setItem("uuid", res.data.id)
            sessionStorage.setItem("is_used", res.data.user_take.is_used)
            sessionStorage.setItem("is_email_confirm", res.data.user_take.is_email_confirm)
            Cookies.set("uuid", res.data.id, { secure: true })
            Cookies.set("is_used", res.data.user_take.is_used, { secure: true })
            Cookies.set("is_email_confirm", res.data.user_take.is_email_confirm, { secure: true })
            // if (me.$route.name === "login") {
            // window.location.href = `/Subscribe?id=${res.data.id}&op=notify_manage`
            // }
          }
        }
        if (res.status === 503) {
          me.main_page_switch = false
          me.user_uuid = null
          // window.location.href = `/Login`
        }
      })
    },
  },
}
