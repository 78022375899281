<template>
  <div class="row">
    <div class="col-12">
      <div class="row row-cols-1 mt-4">
        <main class="mt-3 w-100 m-auto">
          <!-- <div> -->
          <div class="text-center w100 p-0 py-2 align-middle">
            <b class="text-muted">
              <h3>
                <p class="text-primary">懶人包快速選單</p>
              </h3>
            </b>
          </div>
          <div class="row row-cols-md-4 row-cols-2 g-4">
            <div class="col" v-for="(row, key) in lowfare_lists" :key="key">
              <div class="card h-100">
                <div class="card-body" :style="'color: black; background-color:' + row.banner_color">
                  <h5 class="card-title">{{ row.mainTitle }}</h5>
                  <p class="card-text fs-5"><a :href="'./Lowfare?ref=' + row.ref">前往懶人包</a></p>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </main>
      </div>
    </div>
  </div>
</template>
<!-- <style lang="css" src="@/css/a.css" scoped></style> -->
<script>
  import axios from "axios"
  import GlobalVar from "@/mixins/global_var.js"
  export default {
    mixins: [GlobalVar],
    data: function () {
      return {
        lowfare_lists: [],
      }
    },
    mounted() {
      this.getAllLowfareInfo()
    },
    methods: {
      async getAllLowfareInfo() {
        const me = this

        try {
          const response = await axios.get("./lowfare.json")
          let jsonData = response.data

          const result = me.searchJson(jsonData, {
            show_to_index: true,
          })

          if (result.length === 0) {
            me.$alert(`參數讀取錯誤1`).then(() => {})
          } else {
            me.lowfare_lists = result
          }
        } catch (error) {
          me.$alert(`參數讀取錯誤2`).then(() => {
            window.location.href = `./`
          })
          console.error("Error loading JSON data:", error)
        }
      },
    },
  }
</script>
<style></style>
